import { useEffect, useState } from 'react';
import i18n from 'src/utils/translate';
import { LinkButton } from 'styles-js/buttons';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import Button from 'src/components/buttons/button';
import { useSelector } from 'src/store/store';
import { Screens } from 'src/modules/shared/modal/components/signup/onboardingModal';
import InformationCard from 'src/components/informationCard';
import Spacer from 'src/components/spacer';
import { Body, Footer } from 'src/components/modals/modal';
import { List, P, SubTitle } from 'src/modules/shared/modal/components/signup/onboardingScreens/sharedStyles';
import { ModalTitle } from 'styles-js/modals';

type Props = {
  username: string;
  setCurrentScreen: (screen: Screens) => void;
}
export default function AcceptTerms({ username, setCurrentScreen }: Props) {
  const { signupUser } = useSelector(({ modal }) => modal.modalProps);
  const currentUser = useSelector(({ context }) => context.currentUser);
  const isLoading = useSelector(({ modals }) => modals.signupOnboarding.isLoading);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    setIsButtonDisabled(isLoading);
    if (currentUser) setCurrentScreen(Screens.AcceptResearch);
  }, [currentUser, isLoading, setCurrentScreen]);

  const onClick = () => {
    trackFootfallEvent(Event.Clicked, { clickType: 'join-agreed' });
    signupUser({ username });
  };

  return (
    <>
      <Body>
        <ModalTitle>{i18n.t('Terms and Privacy Policy')}</ModalTitle>
        <P>
          {i18n.t('By joining, you agree to')}{' '}
          <LinkButton onClick={() => {
            trackFootfallEvent(Event.Clicked, { clickType: 'join-our-terms' });
            helpScoutArticle('147-terms', { type: 'modal' });
          }}
          >
            {i18n.t('our terms')}
          </LinkButton>
          {', '}
          <LinkButton onClick={() => {
            trackFootfallEvent(Event.Clicked, { clickType: 'join-privacy' });
            helpScoutArticle('148-privacy', { type: 'modal' });
          }}
          >
            {i18n.t('Privacy Policy')}
          </LinkButton>
          {' '}{i18n.t('and')}{' '}
          <LinkButton onClick={() => {
            trackFootfallEvent(Event.Clicked, { clickType: 'join-cookie' });
            helpScoutArticle('150-cookies', { type: 'modal' });
          }}
          >
            {i18n.t('Cookie Policy')}
          </LinkButton>
          {'.'}
        </P>
        <Spacer />
        <InformationCard>
          <SubTitle>{i18n.t('The Highlights')}</SubTitle>
          <List>
            <li>{i18n.t('You must be 18 or older')}</li>
            <li>{i18n.t('Your contact information is not shared with 3rd parties without your consent')}</li>
            <li>{i18n.t('Do not use the site as a substitute for professional health advice')}</li>
            <li>{i18n.t('Your contributions must comply with the laws of the country from which they are posted')}</li>
            <li>{i18n.t('We only use information about you consistent with applicable law')}</li>
          </List>
        </InformationCard>
      </Body>
      <Footer>
        <Button
          dataTestId="accept-terms-join"
          disabled={isButtonDisabled}
          text={i18n.t('Join')}
          onClick={onClick}
        />
      </Footer>
    </>
  );
}
