// @ts-strict-ignore
import config from 'config/config';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { isCategoryAccepted, Category } from 'src/utils/cookie';
import { initialiseGPT } from 'src/utils/adverts';

const cookieControlConfig = {
  apiKey: config.COOKIE_CONTROL_API_KEY,
  product: 'PRO',
  consentCookieExpiry: 365,
  necessaryCookies: ['AWSALB', 'AWSALBCORS', 'huBv', 'huLang', 'huSessID'],
  logConsent: false,
  initialState: 'box',
  layout: 'popup',
  rejectButton: false,
  acceptBehaviour: 'all',
  settingsStyle: 'button',
  toggleType: 'checkbox',
  notifyDismissButton: false,
  sameSiteValue: 'Lax',
  onLoad: function onLoad() {
    // Send footfall event when "I'am ok with that" button is clicked
    const notifyAcceptBtn = window.document.getElementById('ccc-notify-accept');
    if (notifyAcceptBtn) notifyAcceptBtn.addEventListener('click', () => sendFootfallEvent(Event.CookieOptinFirstScreen));
    // Add event listeners on CTAs when cookie modal is opened
    const notifyOptionsBtn = window.document.querySelector('.ccc-notify-button:not(.ccc-accept-button)');
    if (notifyOptionsBtn) notifyOptionsBtn.addEventListener('click', addListenersOnModalButtons);
  },
  optionalCookies: [
    {
      name: 'functionality',
      label: 'Functionality cookies',
      description: 'Help us improve your experience by remembering preferences (e.g. Google/Facebook login, videos).',
      cookies: [
        // Third-party cookies from https://www.youtube.com
        'LOGIN_INFO', 'PREF', 'VISITOR_INFO1_LIVE', 'YSC',
        // Third-party cookies from Google Sign In
        'ACCOUNT_CHOOSER', 'GAPS', 'G_AUTHUSER_H', 'G_ENABLED_IDPS', 'LSID', 'LSOLH', 'SMSV', 'user_id',
        // Third-party cookies from Facebook Sign In
        'c_user', 'datr', 'dpr', 'fr', 'locale', 'oo', 'sb', 'spin', 'wd', 'xs', '_fbp',
      ],
      onAccept: function onAccept() {
        // Google Sign in
        const googleAuthTag = document.getElementById('google-auth');
        if (!googleAuthTag) {
          const googleAuthTag = document.createElement('script');
          googleAuthTag.src = 'https://accounts.google.com/gsi/client';
          googleAuthTag.id = 'google-auth';
          googleAuthTag.async = true;
          document.body.appendChild(googleAuthTag);
        }
      },
      onRevoke: function onRevoke() {
        // Disable Facebook Sign In
        window.fbIsInit = undefined;
      },
      thirdPartyCookies: [
        { 'name': 'Google privacy policy', 'optOutLink': 'https://policies.google.com/privacy' },
        { 'name': 'Facebook privacy policy', 'optOutLink': 'https://en-gb.facebook.com/policies/cookies/' },
      ],
    },
    {
      name: 'advertising',
      label: 'Advertising cookies',
      description: 'Help us display relevant health campaigns (e.g. research opportunities).',
      cookies: [
        // Google Ads Optimization
        'APISID', 'HSID', 'CONSENT', 'NID', 'SAPISID', 'OGPC', 'SSID', 'SID',
        // Google DoubleClick
        '1P_JAR', 'DSID', 'RUL', 'test_cookie', '__gads',
        'IDE', // Google DoubleClick, one of the main advertising cookies
        'ANID', // Google
        'SIDCC', // Google
      ],
      onAccept: initialiseGPT,
      thirdPartyCookies: [
        { 'name': 'Google advertising cookies', 'optOutLink': 'https://policies.google.com/technologies/ads' },
      ],
    },
    {
      name: 'analytics',
      label: 'Analytics cookies',
      description: 'Help us optimize our website to personalize your experience.',
      cookies: ['FOOTFALL_SESSION', '_ga', '_ga_*', '_gat*', '_gid', 'huReferrer', 'huReferrerPath'],
      onAccept: function onAccept() {
        // Add Google Analytics
        const gtagScript = document.getElementById('gtag');
        if (!gtagScript) {
          const elements = document.getElementsByTagName('script')[0];
          const gtagTag = document.createElement('script');
          gtagTag.src = `https://www.googletagmanager.com/gtag/js?id=${config.ga4TrackingId}`;
          gtagTag.id = 'gtag';
          gtagTag.async = true;
          elements?.parentNode?.insertBefore(gtagTag, elements);
        }
        window[`ga-disable-${config.ga4TrackingId}`] = false;
        window[`ga-disable-${config.gaTrackingId}`] = false;
      },
      onRevoke: function onRevoke() {
        window[`ga-disable-${config.ga4TrackingId}`] = true;
        window[`ga-disable-${config.gaTrackingId}`] = true;
      },
    },
  ],
  branding: {
    removeAbout: true,
    removeIcon: true,
    fontSizeHeaders: '21px', // h3
    fontSize: '17px',
    fontColor: '#333333',
    backgroundColor: '#FFFFFF',
    acceptText: '#FFFFFF', // accept button on banner
    acceptBackground: '#1879CD', // accept button on banner
  },
  text: {
    // For the banner
    notifyTitle: 'Our use of cookies',
    notifyDescription: 'We use cookies to improve your experience, understand how our site is used and show you relevant campaigns.',
    accept: "I'm OK with that",
    settings: 'Options',
    // For the modal
    title: 'Your options',
    intro: 'We use cookies to improve your experience, understand how our site is used and show you relevant campaigns.',
    necessaryTitle: 'Strictly necessary cookies',
    necessaryDescription: 'Enable core functionality such as page navigation. The website cannot function properly without these cookies.',
    acceptSettings: 'Allow all',
  },
  statement: {
    description: 'Take a look at our',
    name: 'cookie policy',
    url: 'https://support.healthunlocked.com/article/150-cookies',
    updated: '24/11/2021',
  },
};

export default function cookieControl(): void {
  const init = () => {
    window.CookieControl.load(cookieControlConfig);
    // When the user changes their settings via "Cookie settings" in the footer, we also track cookie consent
    // with Footfall when cookie modal is closed. We modify the CookieControl.open method to check for modal close
    if (!window.CookieControl.huModified) {
      const ccOpen = window.CookieControl.open;
      const huOpen = () => {
        ccOpen();
        addListenersOnModalButtons();
      };
      window.CookieControl.open = huOpen;
      window.CookieControl.huModified = true;
    }
  };

  // BB-2739
  if (!window.CookieControl) {
    const interval = window.setInterval(() => {
      if (window.CookieControl) {
        init();
        window.clearInterval(interval);
      }
    }, 1000);
  } else {
    init();
  }
}

const sendFootfallEvent = (clickType: Event) => {
  trackFootfallEvent(Event.Clicked, {
    clickType,
    clickMetadata: {
      functionality: isCategoryAccepted(Category.Functionality),
      advertising: isCategoryAccepted(Category.Advertising),
      analytics: isCategoryAccepted(Category.Analytics),
    },
  });
};

const addListenersOnModalButtons = () => {
  const firstInterval = window.setInterval(() => {
    const modalCloseBtn = window.document.getElementById('ccc-close');
    if (modalCloseBtn) {
      window.clearInterval(firstInterval);
      modalCloseBtn.addEventListener('click', () => sendFootfallEvent(Event.CookieOptinOptionsScreenClose));
    }
  }, 10);

  const secondInterval = window.setInterval(() => {
    const allowAllBtn = window.document.getElementById('ccc-recommended-settings');
    if (allowAllBtn) {
      window.clearInterval(secondInterval);
      allowAllBtn.addEventListener('click', () => sendFootfallEvent(Event.CookieOptinOptionsScreenAllow));
    }
  }, 10);
};
