// @ts-strict-ignore
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Body, Footer } from 'src/components/modals/modal';
import { useDispatch, useSelector } from 'src/store/store';
import { Event } from 'src/constants/footfall';
import { trackFootfallEvent } from 'src/utils/footfall';
import i18n from 'src/utils/translate';
import { getCommunityLogoUrl } from 'src/utils/linkUtils';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import { closeModal, showModal } from 'src/modules/shared/modal/actions';
import Button from 'src/components/buttons/button';
import Icon from 'src/components/icon';
import { List, P } from 'src/modules/shared/modal/components/signup/onboardingScreens/sharedStyles';
import { LinkButton } from 'styles-js/buttons';
import { titleLarge, textLight } from 'styles-js/mixins/typography';
import { ModalTitle } from 'styles-js/modals';
import { ModalType } from 'src/constants/modalTypes';

export default function Welcome() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { redirectOnClose } = useSelector(({ modal }) => modal.modalProps);
  const currentUser = useSelector((state) => state.context?.currentUser);
  const currentCommunity = useSelector((state) => state.context?.currentCommunity);
  const community = currentCommunity || currentUser?.followedCommunities.filter(({ slug }) => slug !== 'blog')?.[0] || null;

  const onCloseModal = () => {
    if (redirectOnClose) {
      router.push(redirectOnClose);
    } else {
      router.reload();
    }
  };
  const onClose = () => {
    trackFootfallEvent(Event.ProfileSurvey, { clickType: 'clicked-maybe-later-welcome-modal' });
    onCloseModal();
  };
  const onAccept = async () => {
    await dispatch(closeModal());
    dispatch(showModal({ modalType: ModalType.ProfileSurvey, modalProps: { onCloseModal } }));
  };

  return (
    <>
      <Body>
        <Header>
          <Logos>
            <UserLogo imageUrl={currentUser?.avatarImageUrl} />
            {community?.logoImageHash  && <CommunityLogo imageUrl={getCommunityLogoUrl(community.logoImageHash)} />}
          </Logos>
          <ModalTitle>{i18n.t('Welcome')}</ModalTitle>
          <Username>{currentUser?.username}</Username>
          <CommunityName>{i18n.t('You joined')}{' '}{community?.name}</CommunityName>
        </Header>
        <P>{i18n.t('If you have another minute, please complete your profile for an improved experience. You can add:')}</P>
        <List>
          <li>{i18n.t('Health interests and conditions')}</li>
          <li>{i18n.t('Previous and current treatments')}</li>
          <li>{i18n.t('Health story and challenges')}</li>
        </List>
        <strong>
          {i18n.t('You choose what is visible to others on HealthUnlocked.')}
          {' '}
          <LinkButton onClick={() => helpScoutArticle('101-your-profile-on-healthunlocked')}>
            {i18n.t('Learn more')}<Icon icon="open-right" />
          </LinkButton>
        </strong>
      </Body>
      <Footer>
        <Button type="link" onClick={onClose}>{i18n.t('Maybe Later')}</Button>
        <Button onClick={onAccept}>{i18n.t('Complete Profile')}</Button>
      </Footer>
    </>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 5px;
  }
`;
const Logos = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  margin-bottom: 20px;
`;
const Logo = styled.div<{ imageUrl: string }>`
  position: absolute;
  background-image: url("${({ imageUrl }) => imageUrl}");
  border: solid 3px ${props => props.theme.colorWhite};
  border-radius: 50%;
  background-clip: padding-box;
  background-size: cover;
`;
const UserLogo = styled(Logo)`
  height: 100%;
  width: 100%;
`;
const CommunityLogo = styled(Logo)`
  height: 50%;
  width: 50%;
  bottom: -6px;
  right: -6px;
`;
const Username = styled.div`
  ${titleLarge}
  margin-bottom: 5px;
`;
const CommunityName = styled.div`
  ${textLight}
  margin-bottom: 20px;
`;
