// @ts-strict-ignore
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import config from 'config/config';

const isServer = typeof window === 'undefined';

export const trackPageView = (url, currentUser) => {
  trackFootfallEvent(Event.ViewedPage);
  if (isServer || config.environment === 'development') return;

  // Workaround for Next.js issue: https://github.com/vercel/next.js/issues/6025
  // Page view event is fired with correct path but title is sometimes incorrect (previous page's title)
  // Issue might be fixed in Next.js versions above 9.0.4
  setTimeout(() => {
    // Remove the unread notifications/messages count
    const pageTitle = document.title.split(') ').pop();

    // Configure custom dimensions for GA4 property
    window.gtag('config', config.ga4TrackingId, {
      'custom_map': { 'dimension1': 'fe_type', 'dimension2': 'user_type' },
      'send_page_view': false, // disable the default page view event
      'fe_type': 'solaris',
      'user_type': !currentUser ? 'Visitor' : 'Member',
    });

    // Configure custom dimensions for UA property
    let customDimensions: Record<string, string> = { 'dimension9': 'fe_type', 'dimension10': 'user_type' };
    if (config.gaTrackingId === 'UA-11066411-23') { // Betelgeuse Staging
      customDimensions = { 'dimension4': 'fe_type', 'dimension5': 'user_type' };
    }
    window.gtag('config', config.gaTrackingId, {
      'custom_map': customDimensions,
      'send_page_view': false, // disable the default page view event
      'fe_type': 'solaris',
      'user_type': !currentUser ? 'Visitor' : 'Member',
    });

    // Send manual page view event
    window.gtag('event', 'page_view', {
      'page_location': location.href,
      'page_path': url,
      'page_title': pageTitle,
      'send_to': [ config.ga4TrackingId, config.gaTrackingId ],
    });
  }, 0);
};

// Track events for UA property
export const trackEvent = (category, action, label = undefined, value = undefined, isNonInteraction = false) => {
  if (isServer || config.environment === 'development') return;
  window.gtag('event', action, {
    'event_category': category,
    'event_label': label,
    'value': value,
    'non_interaction': isNonInteraction,
    'send_to': config.gaTrackingId, // only send to UA property
  });
};

// Track events for GA4 property
export const trackAccountCreatedEvent = (action) => {
  if (isServer || config.environment === 'development') return;
  window.gtag('event', 'account_created', {
    'action': action,
    'user_type': 'Member',
    'send_to': config.ga4TrackingId, // only send to G4 property
  });
};


// Community tracking for admins

export const trackPageForPartner = (currentCommunity) => {
  if (isServer || config.environment === 'development' || !currentCommunity || !currentCommunity.gaTrackingCode) return;

  // Workaround for Next.js issue: https://github.com/vercel/next.js/issues/6025
  // Page view event is fired with correct path but title is sometimes incorrect (previous page's title)
  // Issue might be fixed in Next.js versions above 9.0.4
  setTimeout(() => {
    // Remove the unread notifications/messages count
    const pageTitle = document.title.split(') ').pop();

    // By default the config command sends a pageview
    window.gtag('config', currentCommunity.gaTrackingCode, {
      'page_location': location.href,
      'page_path': location.pathname + location.search + location.hash,
      'page_title': pageTitle,
      'send_to': currentCommunity.gaTrackingCode, // only send to partner property
    });
  }, 0);
};

export const trackEventForPartner = (currentCommunity, category, action, label = '', value = '') => {
  if (isServer || config.environment === 'development'
      || !category || !action || !currentCommunity || !currentCommunity.gaTrackingCode) {
    return;
  }
  window.gtag('event', action, {
    'event_category': category,
    'event_label': label,
    'value': value,
    'send_to': currentCommunity.gaTrackingCode, // only send to partner property
  });
};
