// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import { Event } from 'src/constants/footfall';
import api from 'src/utils/api';
import { trackFootfallEvent } from 'src/utils/footfall';
import { getCommunityLogoUrl } from 'src/utils/linkUtils';
import { setAlert } from 'src/components/alert/actions';
import { Community } from 'src/modules/shared/modal/components/signup/reducer';
import { Blog } from 'src/constants/types';

export enum Action {
  UpdateCommunitySuggestions = 'UpdateCommunitySuggestions',
  UpdateCommunitySuggestionsSuccess = 'UpdateCommunitySuggestionsSuccess',
  UpdateCommunitySuggestionsFailure = 'UpdateCommunitySuggestionsFailure',

  FollowCommunity = 'FollowCommunity',
  FollowCommunitySuccess = 'FollowCommunitySuccess',
  FollowCommunityFailure = 'FollowCommunityFailure',

  UnfollowCommunity = 'UnfollowCommunity',
  UnfollowCommunitySuccess = 'UnfollowCommunitySuccess',
  UnfollowCommunityFailure = 'UnfollowCommunityFailure',
}

type CommunityRelated = {
  groupId: number;
  groupCode: string;
  name: string;
  description: string;
  bannerUrl: string;
  imageUrl: string;
  membersInCommunity: number;
}
export function fetchCommunities() {
  return async (dispatch) => {
    try {
      dispatch({ type: Action.UpdateCommunitySuggestions });
      const response = await api.getPublic('communities/related');
      const communities: CommunityRelated[] = response?.data ?? [];

      if (communities?.length > 0) {
        dispatch({
          type: Action.UpdateCommunitySuggestionsSuccess,
          communities: communities.map(({ membersInCommunity, imageUrl, name, groupCode }) => ({
            followerCount: membersInCommunity,
            logoUrl: imageUrl,
            name,
            slug: groupCode,
          })),
        });
      }
    } catch (ex) {
      captureException(ex);
    }
  };
}

type CommunitySearch = {
  bannerImageHash: string;
  communityId: number;
  description: string;
  logoImageHash: string;
  name: string;
  slug: string;
  totalMembers: number;
}
export function searchCommunities(query: string) {
  return async (dispatch) => {
    try {
      dispatch({ type: Action.UpdateCommunitySuggestions });
      const response = await api.getPublic('search/communities', { params: { q: query } });
      const communities: CommunitySearch[] = response?.data?.communities ?? [];

      const searchQuery = communities.length === 0 ? `no-results:${query}` : query;
      trackFootfallEvent(Event.SearchedSite, { searchQuery, searchType: 'communities-join' });

      if (!communities.length) {
        dispatch({ type: Action.UpdateCommunitySuggestionsFailure });
      } else {
        dispatch({
          type: Action.UpdateCommunitySuggestionsSuccess,
          communities: communities.map(({ logoImageHash, name, slug, totalMembers }) => ({
            logoUrl: getCommunityLogoUrl(logoImageHash),
            name,
            slug,
            followerCount: totalMembers,
          })),
        });
      }
    } catch (ex) {
      dispatch({ type: Action.UpdateCommunitySuggestionsFailure });
      captureException(ex);
    }
  };
}

export function followCommunitySignup({ community, userId }: { community: Community, userId: number }) {
  return async (dispatch) => {
    dispatch({ type: Action.FollowCommunity });
    try {
      await api.post(`private/communities/${community.slug}/follow/${userId}`);
      dispatch({ type: Action.FollowCommunitySuccess, community });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: Action.FollowCommunityFailure });
      dispatch(setAlert(ex.message));
    }
  };
}

export function unfollowCommunitySignup({ communitySlug, userId }: { communitySlug: string, userId: number }) {
  return async (dispatch) => {
    dispatch({ type: Action.UnfollowCommunity });
    try {
      await api.del(`private/communities/${communitySlug}/follow/${userId}`);
      dispatch({ type: Action.UnfollowCommunitySuccess, communitySlug });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: Action.UnfollowCommunityFailure });
      dispatch(setAlert(ex.message));
    }
  };
}

export function userFollowingBlog() {
  return async (dispatch) => {
    dispatch({ type: Action.FollowCommunity });
    try {
      const { data } = await api.getPublic('communities/blog');
      const community = { ...data as Blog, logoUrl: getCommunityLogoUrl(data.logoImageHash) };
      dispatch({ type: Action.FollowCommunitySuccess, community });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: Action.UnfollowCommunityFailure });
    }
  };
}
