// @ts-strict-ignore
import { trackEvent, trackAccountCreatedEvent, trackEventForPartner } from 'src/utils/analytics';
import { clearTrackingSession, trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { getBranch } from 'src/utils/cookie';
import actionTypes from 'src/modules/shared/context/actionTypes';

const tracking = store => next => action => {
  const result = next(action);
  const state = store.getState();
  switch (action.type) {
    case actionTypes.SIGN_UP_SUCCESS:
      // the current session doesn't have a membershipId so in order to update it we need to clear the current one
      clearTrackingSession();
      trackFootfallEvent(Event.SignedUp);
      trackEvent('Account', `Created (${getBranch()})`); // UA
      trackAccountCreatedEvent(`Created (${getBranch()})`); // GA4
      trackEventForPartner(state.context.currentCommunity, 'Account', 'Created');
      break;
    case actionTypes.SOCIAL_SIGN_UP_SUCCESS:
      // the current session doesn't have a membershipId so in order to update it we need to clear the current one
      clearTrackingSession();
      trackFootfallEvent(Event.SignedUp);
      trackEvent('Account', `Created ${action.socialType} (${getBranch()})`); // UA
      trackAccountCreatedEvent(`Created ${action.socialType} (${getBranch()})`); // GA4
      trackEventForPartner(state.context.currentCommunity, 'Account', 'Created');
      break;
    case actionTypes.LOGOUT:
      trackFootfallEvent(Event.Clicked, { clickType: 'logout' });
      break;
    default:
      break;
  }
  return result;
};
export default tracking;
