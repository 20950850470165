// @ts-strict-ignore
import actionTypes from 'src/modules/user/shared/actionTypes';
import contextActionTypes from 'src/modules/shared/context/actionTypes';
import { CurrentUserProfile } from 'src/constants/types';

export const initialState: CurrentUserProfile = {
  basics: {
    aboutMe: null,
    age: null,
    country: null,
    countryCode: null,
    dateOfBirth: null,
    ethnicity: null,
    gender: null,
    genderSelfDescription: null,
    isConditionsEnabled: true,
    isCountryPrivate: false,
    isDateOfBirthPrivate: false,
    isEthnicityPrivate: false,
    isGenderPrivate: false,
    isInterestsEnabled: true,
    isRegionPrivate: false,
    percentageComplete: 0,
    region: null,
    regionCode: null,
    userBannerHash: null,
    userImageHash: '',
  },
  conditions: [],
  interests: [],
  isLoading: true,
};

export default function Reducer(state = initialState, action): CurrentUserProfile {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        ...action.data,
        isLoading: false,
      };
    }
    case actionTypes.UPDATE_PROFILE_BASICS_SUCCESS:
      return {
        ...state,
        basics: action.data,
      };
    case actionTypes.ADD_PROFILE_CONDITIONS_SUCCESS: {
      const existingCondition = state.conditions.find(({ id }) => id === action.condition.id);
      if (existingCondition) {
        return {
          ...state,
          conditions: [existingCondition, ...state.conditions.filter(({ id }) => id !== action.condition.id)],
        };
      } else {
        return {
          ...state,
          conditions: [action.condition, ...state.conditions],
        };
      }
    }
    case actionTypes.UPDATE_PROFILE_CONDITION_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map(((condition) => (
          condition.id === action.condition.id ? { ...action.condition, treatments: condition.treatments } : condition)
        )),
      };
    case actionTypes.DELETE_PROFILE_CONDITION_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.filter(({ id }) => id !== action.id),
      };
    case actionTypes.ADD_PROFILE_TREATMENT_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map((condition) => {
          if (condition.id === action.id) {
            const existingTreatment = condition.treatments.find(({ id }) => id === action.treatment.id);
            if (existingTreatment) {
              return { ...condition, treatments: [existingTreatment, ...condition.treatments.filter(({ id }) => id !== action.treatment.id)] };
            } else {
              return { ...condition, treatments: [action.treatment, ...condition.treatments] };
            }
          } else {
            return condition;
          }
        }),
      };
    case actionTypes.UPDATE_PROFILE_TREATMENT_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map((condition) => (
          condition.entityId === action.conditionId
            ? { ...condition, treatments: condition.treatments.map((treatment) => treatment.id === action.id ? action.data : treatment) }
            : condition
        )),
      };
    case actionTypes.DELETE_PROFILE_TREATMENT_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map((condition) => (
          condition.id === action.conditionId
            ? { ...condition, treatments: condition.treatments.filter(({ id }) => id !== action.id) }
            : condition
        )),
      };
    case actionTypes.ADD_PROFILE_INTERESTS_SUCCESS:
      return {
        ...state,
        interests: action.interests,
      };
    case actionTypes.DELETE_PROFILE_INTEREST_SUCCESS:
      return {
        ...state,
        interests: state.interests.filter(({ id }) => id !== action.id),
      };
    case contextActionTypes.LOGOUT_SUCCESS:
      return initialState;
  }
  return state;
}
